.companyInfo {
    border-bottom: 1px solid #dedede;
    border-top: 1px solid #dedede;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    align-content: space-around;
    color: #dedede;
}

.companyInfo .avatar {
    width: 25%;
    border-radius: 50%;
}

.companyInfo .avatar img {
    border-radius: 50%;
    width: 53px;
    border: 1px solid #dedede;
}

.companyInfo .infos {
    width: 75%;
    padding-left: 15px;
    margin-top: 10px;
}

a:link {
    text-decoration: none;
}

.ant-menu-item-selected {
    background-color: #1677ff;
    border-radius: 0 !important;
}

.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

.react-tel-input .form-control {
    width: 100% !important;
    height: 58px !important;
}

.file-upload {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    background: rgba(0, 0, 0, 0.02);
    border: 1px dashed #d9d9d9;
    border-radius: 8px;
    cursor: pointer;
    transition: border-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.file-upload input[type=file] {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.file-upload .ant-upload-drag-icon .anticon {
    color: #1677ff;
    font-size: 48px;
}

.prwImg {
    width: 100%;
}

img,
svg {
    vertical-align: unset !important;
}

.itemIcon {
    border-radius: 50%;
    border: 1px solid #dedede;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
}


.iphone-x {
    position: relative;
    margin: 10px auto;
    width: 360px;
    height: 760px;
    background-color: #7371ee;
    background-image: linear-gradient(60deg, #7371ee 1%, #a1d9d6 100%);
    border-radius: 40px;
    box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919, 0px 0px 0px 20px #111;
}

.iphone-x ::-webkit-scrollbar {
    width: 10px;
}
iframe {
    overflow: scroll;
    width: calc(100% + 20px);
    height: 100%;
    border: 1px solid black;
}

/* Track */
.iphone-x ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.iphone-x ::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.iphone-x ::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.iphoneBody {

    border-radius: 40px;
    height: 100%;
    overflow: hidden;

}

.iphoneContent {
    overflow: hidden;

    height: 100%;
}

.iphone-x:before,
.iphone-x:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.iphone-x:after {
    bottom: 7px;
    width: 140px;
    height: 4px;
    background-color: #f2f2f2;
    border-radius: 10px;
}

.iphone-x:before {
    top: 0px;
    width: 56%;
    height: 30px;
    background-color: #1f1f1f;
    border-radius: 0px 0px 40px 40px;
    z-index: 9999999;
}

.iphone-x .speaker,
.iphone-x .camera {
    position: absolute;
    display: block;
    color: transparent;
}

.iphone-x .speaker {
    top: 0px;
    left: 50%;
    transform: translate(-50%, 6px);
    height: 8px;
    width: 15%;
    background-color: #101010;
    border-radius: 8px;
    box-shadow: inset 0px -3px 3px 0px rgba(255, 255, 255, 0.2);
    z-index: 9999999;
}

.iphone-x .camera {
    left: 10%;
    top: 0px;
    transform: translate(180px, 4px);
    width: 12px;
    height: 12px;
    background-color: #101010;
    border-radius: 12px;
    box-shadow: inset 0px -3px 2px 0px rgba(255, 255, 255, 0.2);
    z-index: 9999999;
}

.iphone-x .camera:after {
    content: "";
    position: absolute;
    background-color: #2d4d76;
    width: 6px;
    height: 6px;
    top: 2px;
    left: 2px;
    top: 3px;
    left: 3px;
    display: block;
    border-radius: 4px;
    box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
    z-index: 9999999;
}

.cropper-crop-box, .cropper-view-box {
    border-radius: 50%;
    }
    
    .cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    outline: 0;
    }